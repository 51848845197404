import { BlockTopText } from '@renderer-ui-library/molecules'
import { Grid } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import React from 'react'
import { IPopularMachineTypeBlockData } from './IPopularMachineTypeBlockData'
import { PopularMachineType } from './PopularMachineType'

export const PopularMachineTypeBlock: React.FC<
  BlockProps<IPopularMachineTypeBlockData>
> = React.memo(({ data }) => {
  const { isMobile } = useMediaQuery()

  return (
    <BlockWrapper blockData={data}>
      {(data.topTitle || data.topText) && (
        <BlockTopText
          title={data.topTitle}
          text={data.topText}
          highlight={data.highlight}
        />
      )}

      <Grid
        container
        rowSpacing={isMobile ? 1 : 3}
        columnSpacing={isMobile ? 1 : 2}
      >
        {data.popularMachineColumn.map((item, index) => (
          <PopularMachineType
            key={`popular-machine_${index}`}
            highlight={data.highlight}
            {...item}
          />
        ))}
      </Grid>
    </BlockWrapper>
  )
})

PopularMachineTypeBlock.displayName = 'PopularMachineTypeBlock'
