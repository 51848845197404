import { ImageLoader } from '@internal/utils/media/ImageLoader'
import { getMediaSrc } from '@internal/utils/media/getMediaSrc'
import { Grid, Typography } from '@renderer-ui-library/mui'
import classnames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import React, { useCallback, useMemo } from 'react'
import { usePrioritizedUrl } from 'utils/routing/urls/usePrioritizedUrl'
import { tracker } from 'utils/tracking/tracker'
import chevronsDarkIcon from '../../renderer-ui-library/assets/chevrons-dark.svg'
import chevronsIcon from '../../renderer-ui-library/assets/chevrons.svg'
import { PopularMachineColumnType } from './IPopularMachineTypeBlockData'
import styles from './popularMachineTypeBlock.module.scss'

interface Props extends PopularMachineColumnType {
  highlight: boolean
}

export const PopularMachineType: React.FC<Props> = React.memo(
  ({ media, title, cta, highlight }) => {
    const { getPrioritizedUrl } = usePrioritizedUrl()

    const url = useMemo(
      () => cta && getPrioritizedUrl(cta),
      [getPrioritizedUrl, cta]
    )

    const handleClick = useCallback(() => {
      tracker.trackEvent({
        name: 'click',
        target_name: 'block.popular_machine_type',
        is_interaction: true,
      })
    }, [])

    if (!url) {
      return null
    }

    return (
      <Grid item xs={6} md={4}>
        <Link href={url} passHref prefetch={false} onClick={handleClick}>
          <span
            className={classnames(styles.wrapper, {
              [styles.highlight]: highlight,
            })}
          >
            {media?.data && (
              <img
                alt=''
                loading='lazy'
                {...getMediaSrc(
                  { data: { ...media.data[0] } },
                  {
                    ratio:
                      typeof media.data[0].attributes.ratio === 'number' &&
                      media.data[0].attributes.ratio <= 1
                        ? 'original'
                        : 16 / 9,
                  }
                )}
              />
            )}
            <span className={styles.title}>
              <Image
                src={highlight ? chevronsDarkIcon.src : chevronsIcon.src}
                className={styles.icon}
                loading='lazy'
                alt=''
                loader={ImageLoader}
                width={58}
                height={40}
              />
              <Typography variant='h5' component='span' color='inherit' noWrap>
                {title}
              </Typography>
            </span>
          </span>
        </Link>
      </Grid>
    )
  }
)

PopularMachineType.displayName = 'PopularMachineType'
